<template>
  <ul v-if="props.data.length" class="list-view">
    <slot name="title">
      <li v-for="(item, i) in getData()" :key="i" class="mx-2">
        <span v-if="item.amount">{{ item.amount }} - </span>
        <SmartLink v-if="item.uri" :to="item.uri"> {{ item.title }} </SmartLink>
        <span v-else> {{ item.title }}</span>
        <em v-if="item?.date" class="mx-2">{{ item.date }}</em>
      </li>
    </slot>
  </ul>
</template>

<script setup>
import { formatDate, formatMoney } from "@/lib/tools";
const props = defineProps({
  data: { type: Array, required: true, default: () => [] },
  type: { type: String, required: false, default: "" },
});

const getData = () => {
  return props.data
    ? props.data.map(el => {
        return {
          title: el?.Title || el?.Event || el?.["Meta Title"] || el?.Game?.title || el?.["Play at"]?.[0]?.title,
          nick: _.get(el, ["Download Button", "downloadNick"], ""),
          uri: el?.uri || el?.Game?.uri || el?.["Play at"]?.[0]?.uri,
          date: _.get(el, ["Date"]) ? formatDate(el.Date) : "",
          amount: el?.Amount ? formatMoney(el.Amount) : "",
        };
      })
    : null;
};
</script>
